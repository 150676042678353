'use strict';

module.exports = {
  url: 'https://richardhart.io',
  pathPrefix: '/',
  title: 'Richard Hart',
  subtitle: '',
  copyright: '© All rights reserved.',
  disqusShortname: '',
  postsPerPage: 4,
  googleAnalyticsId: '267867283',
  useKatex: false,
  menu: [
    {
      label: 'Articles',
      path: '/'
    },
    {
      label: 'About me',
      path: '/pages/about'
    }
  ],
  convertKit: {
    formId: 2175663,
    template: 'mills',
    emailPlaceholder: 'Enter an email address',
    submitText: 'Sign up',
  },
  author: {
    name: 'Richard Hart',
    photo: '/me.png',
    bio: 'Tech Lead, Staff Software Engineer',
    contacts: {
      email: 'richardhart92@gmail.com',
      facebook: '',
      telegram: '',
      twitter: '@R_Hart',
      github: 'RichardH92',
      rss: '',
      vkontakte: '',
      linkedin: 'richard-hart-aa312262/',
      instagram: '',
      line: '',
      gitlab: '',
      weibo: '',
      codepen: '',
      youtube: '',
      soundcloud: '',
      medium: '',
    }
  }
};
